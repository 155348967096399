import React from 'react';
import PropTypes from 'prop-types';

import withBounds from '../../hoc/withBounds';
import withSvg from '../../hoc/withSvg';

const Search = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    alt="Søk"
  >
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        fill="#2C0931"
        fillRule="nonzero"
        fillOpacity="0.6"
        d="M10.5 3a7.5 7.5 0 1 1 0 15 7.5 7.5 0 0 1 0-15zm0 2a5.5 5.5 0 1 0 0 11 5.5 5.5 0 0 0 0-11zm3.672 10.586 1.414-1.414 5.123 5.125a1 1 0 0 1-1.414 1.414l-5.123-5.125z"
      />
    </g>
  </svg>
);

Search.propTypes = {
  theme: PropTypes.object
};

export default withBounds(withSvg(Search));
